import type { DTO, Nullable } from '@/type-utils';
import StaleWhileRevalidate from '@/utils/StaleWhileRevalidate';
import { isNullOrZero } from '@/utils/null-utils';
import type { IImage, ImageLayout } from '.';
import { MediaModel } from '..';
import MediaService, {
  IMediaOptimizationOptions
} from '../../../isomorphic/MediaService';

/**
 * Provides a way to manipulate Images.
 */
export default class ImageModel
  extends MediaModel<DTO<IImage>>
  implements IImage
{
  private _alt: string;
  private _layout: Nullable<ImageLayout>;
  private _blurURL: Nullable<string>;
  private _caption: Nullable<string>;
  private _aspectRatio: Nullable<number>;

  /**
   * Builds a model from any Image representation.
   * @param image - A Image representation.
   */
  public constructor(image: DTO<IImage>) {
    super(image);

    // TODO: Break this out into a general `punctuate` util.
    // This line adds a period if the alt text does not already end with one.
    this._alt =
      image?.alt !== undefined && image.alt !== null && image.alt !== ''
        ? image.alt.endsWith('.')
          ? image.alt
          : `${image.alt}.`
        : '';
    this._blurURL = image.blurURL;
    this._caption = image.caption;
    this._aspectRatio = image.aspectRatio;
  }

  /** @inheritdoc */
  public override update(image: DTO<IImage>): void {
    super.update(image);

    this._alt = image.alt ?? '';
    this._blurURL = image.blurURL;
    this._caption = image.caption;
    if (!isNullOrZero(image.aspectRatio)) {
      this._aspectRatio = image.aspectRatio;
    }
  }

  /** @inheritdoc */
  public get alt(): string {
    return this._alt;
  }

  /** @inheritdoc */
  public get layout(): Nullable<ImageLayout> {
    return this._layout;
  }

  /** @inheritdoc */
  public get blurURL(): Nullable<string> {
    return this._blurURL;
  }

  /** @inheritdoc */
  public get caption(): Nullable<string> {
    return this._caption;
  }

  /**
   * The aspect ratio of the image as calculated by width divided by height. The "stale"
   * value will be either `null` or `undefined` if the aspect ratio has not been
   * calculated yet.
   * @returns An SWR representing the aspect ratio of the image as calculated by width
   * divided by height.
   */
  public get aspectRatio(): StaleWhileRevalidate<Nullable<number>> {
    return new StaleWhileRevalidate<Nullable<number>>(
      this._aspectRatio,
      this.getAspectRatio()
    );
  }

  /**
   * Explicity requests the aspect ratio of the image as calculated by width divided by height.
   * @returns The aspect ratio of the image as calculated by width divided by height.
   */
  public async getAspectRatio(): Promise<number> {
    if (!isNullOrZero(this._aspectRatio)) {
      return this._aspectRatio;
    }

    return MediaService.getImageAspectRatio(this).then((aspectRatio) => {
      this._aspectRatio = aspectRatio;
      return aspectRatio;
    });
  }

  /**
   * Optimize this image. This image model will be updated
   * with a new, optimized version of the image it represents.
   *
   * @param options - Optimization options.
   */
  public optimize(options: IMediaOptimizationOptions): void {
    this.update(
      ImageModel.toDTO(MediaService.optimizeMedia(this.toDTO(), options))
    );

    // Mark this image as optimized.
    this._optimized = true;
  }

  /** Creates a DTO representation of this Model.
   * @returns A DTO representation of this Image Model.
   */
  public override toDTO(): DTO<IImage> {
    return {
      ...super.toDTO(),
      alt: this._alt,
      blurURL: this._blurURL,
      caption: this._caption,
      aspectRatio: this._aspectRatio
    } as DTO<IImage>;
  }
}
